import { graphql } from 'gatsby';
import _has from 'lodash/has';
import React from 'react';

import ContentBreadcrumb from '../content/ContentBreadcrumb/ContentBreadcrumb';
import ContentHeader from '../content/ContentHeader/ContentHeader';
import FieldSections from '../field/FieldSections/FieldSections';
import Layout from '../layout/layout';
import Seo from '../layout/seo';
import './page.scss';

const PagePage = ({ data, pageContext }) => {
    const page = data.nodePage;
    let {
        breadcrumb: { crumbs },
    } = pageContext;

    // Replace the crumbs generated breadcrumb trail by the one generated via
    // the Drupal menu in gatsby-node.js.
    if (
        pageContext.nodeBreadcrumb &&
        pageContext.nodeBreadcrumb.length &&
        crumbs.length
    ) {
        // Keep the home link and add the trail generated by NodeUtils.
        crumbs = [crumbs[0]].concat(pageContext.nodeBreadcrumb);
    }

    // If the page uses a 2/1 layout and has no banner image, render the title
    // and introduction inside the first column.
    // Note that during rehydration and page reloads this code is executed again,
    // so we need to double check that we add the header only once.
    let contentHeaderSet =
        _has(page, 'r.sections.0.r.col1.0') &&
        React.isValidElement(page.r.sections[0].r.col1[0]);
    if (
        !page.introSet &&
        !_has(page, 'r.banner.r.image.localFile') &&
        _has(page, 'r.sections.0.__typename') &&
        page.r.sections[0]['__typename'] ===
            'paragraph__columns_two_asym_right' &&
        !contentHeaderSet
    ) {
        contentHeaderSet = true;
        let section = (
            <ContentHeader
                key="content-header"
                title={page.title}
                introduction={
                    page.introduction ? page.introduction.processed : null
                }
                fullWidth={true}
            />
        );
        page.r.sections[0].r.col1.unshift(section);
    }

    return (
        <Layout>
            <div className="page__top">
                <ContentBreadcrumb
                    crumbs={crumbs}
                    label={page.title}
                    banner={
                        _has(page, 'r.banner.r.image.localFile') ? true : false
                    }
                />
                {!contentHeaderSet && (
                    <ContentHeader
                        title={page.title}
                        introduction={
                            page.introduction
                                ? page.introduction.processed
                                : null
                        }
                        banner={
                            _has(page, 'r.banner.r.image.localFile')
                                ? page.r.banner.r.image.localFile
                                : null
                        }
                    />
                )}
            </div>
            <div className="page__content">
                <Seo title={page.title} />
                {page.r && page.r.sections && (
                    <FieldSections sections={page.r.sections} />
                )}
            </div>
        </Layout>
    );
};

export default PagePage;

export const query = graphql`
    query ($id: String!) {
        nodePage(id: { eq: $id }) {
            ...nodePageFragment
        }
    }
`;
